
import { Options, Vue } from 'vue-class-component'
import memberPortalMain from '@/components/Memberportal/MemberPortalMain/index.vue'

@Options({
  components: {
    memberPortalMain
  }
})
export default class MemberPortalMain extends Vue {}
