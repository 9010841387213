import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { useRoute } from 'vue-router'
import CommonFunctions, { defaultJuniorLicenseId, defaultLicenseId, defaultClubLicenseId } from '@/components/Utility/Common'
import BaseFunctions, { defaultClubManagerGroupId, defaultAdminBackendGroupId, defaultClubCaptainGroupId } from '@/components/Utility/Base'
import UsersDataService from '@/services/UsersDataService'
import MembersDataService from '@/services/MembersDataService'
import ClubChangeDataService from '@/services/ClubChangeDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { History } from '@/services/HistoryDataService'
import { Products } from '@/services/ProductsDataService'
import { Events } from '@/services/EventsDataService'
import SysProduct from '@/types/SysProduct'
import SysMember, { ClubRoleType } from '@/types/SysMember'
import SysCart, { SysTeamRegistration } from '@/types/SysCart'
import SysClub from '@/types/SysClub'
import SysClubChange, { SysClubChangeFriendlyType } from '@/types/SysClubChange'
import { SysEventRegistration } from '@/types/SysEvent'
import SysTeam from '@/types/SysTeam'
import TeamsDataService from '@/services/TeamsDataService'
import SysMatchRequestDateChange, { SysMatchRequestFriendlyType } from '@/types/SysMatchDateChange'
import MatchDateChangeDataService from '@/services/MatchDateChangeDataService'
import SysTournamentRow, { SysTournamentMatchSchedule, SysTournamentCategoryId } from '@/types/SysTournament'
import { Tournaments } from '@/services/TournamentsDataService'
import SeasonsDataService from '@/services/SeasonsDataService'
import SysSaeson from '@/types/SysSaeson'
import TournamentAdvancedMatchSetRoundsMode from '@/components/Tournaments/TournamentAdvancedMatchSetRoundsMode/index.vue'
import AuthService from '@/services/AuthService'

type requesteeDataType = { firstName: string; lastName: string; userName: string; telephone: string; email: string; address: string; postalNumber: number; city: string; paraDartPlayer: boolean; gender: string; memberStatus: boolean; licenseExpires: string; licenseNumber: string; quarantineStartDate: string, quarantineEndDate: string; birthDate: string; clubName: string }
type memberDataType = { firstName: string; lastName: string; userGroup: number; memberId: number, userName: string; userId: number; licenseExpires: string; birthDate: string; clubID: number, clubName: string, clubLicenseExpires: string | null }
type dataReturnType = { matchChangeRequest: any; newPassword: string; repeatNewPassword: string; memberData: memberDataType; error: any; }

@Options({
  components: {
    TournamentAdvancedMatchSetRoundsMode
  }
})
export default class memberPortalMain extends Vue {
  private tempMember: SysMember[] = []
  memberData: memberDataType = { firstName: '', lastName: '', userGroup: 0, memberId: 0, userName: '', userId: 0, licenseExpires: '', birthDate: '', clubID: 0, clubName: '', clubLicenseExpires: '' }
  error: any = null
  private renewLicenseModal = false
  private renewClubLicenseModal = false
  private todaysDate = new Date().toISOString().split('T')[0]
  private seasonHalfWayPoint = new Date(this.todaysDate).getFullYear() + '-07-01'
  private licenseProduct = {} as SysProduct
  /* private */ changeClubValue = { id: 0, name: 'Vælg en klub' }
  private changeClubOptions: { id: number, name: string}[] = [{ id: 0, name: 'Vælg en klub' }]
  /* private */ changeClubModal = false
  /* private */ requestInProgress: SysClubChange[] = []
  private newRequests: SysClubChange[] = []
  private newRequestsModal = false
  private requesteeMembersArray: SysMember[] = []
  private requesteeInfo: requesteeDataType = { firstName: '', lastName: '', userName: '', telephone: '', email: '', address: '', postalNumber: 0, city: '', paraDartPlayer: false, gender: '', licenseExpires: '', licenseNumber: '', quarantineStartDate: '', quarantineEndDate: '', birthDate: '', clubName: '', memberStatus: true }
  private changePasswordModal = false
  private newPassword = ''
  private repeatNewPassword = ''
  private signedUpTo: SysEventRegistration[] = []
  private signedUpToModal = false
  private matchChangeRequest: SysMatchRequestDateChange[] = []
  private teams: SysTeam[] = [] // Used for requesting match data changes, only the teams in the current season
  private tournamentCategories: SysTournamentCategoryId[] = []
  private manageTeamModal = false
  private prevSeason = {} as SysSaeson
  private currentSeason = {} as SysSaeson
  private nextSeason = {} as SysSaeson
  private activeTeams: SysTeam[] = []
  private categoriesForTeamSignUp: {categoryId: number, categoryName: string, categoryPrice: number, numberOfTeams: number }[] = []
  advancedMatchSetModeModal = false
  advancedMatchSetRoundsModeData = { readOnlyFlag: false, matchIndexNumber: 0, matchSet: [0, 0] }
  memberId = this.parseURL()
  teamProducts: SysProduct[] = []
  seasonValue = { id: 0, name: 'Vælg Sæson' }
  seasonOptions: { id: number, name: string }[] = []
  juniorMembers: {id: number, name: string}[] = []
  juniorMemberOptions: {id: number, name: string}[] = []
  clubRaekke = []

  readonly name : string = 'memberPortalMain'
  $Message: any
  data (): dataReturnType {
    return {
      error: this.error,
      memberData: this.memberData,
      newPassword: this.newPassword,
      repeatNewPassword: this.repeatNewPassword,
      matchChangeRequest: this.matchChangeRequest
    }
  }

  @Watch('juniorMembers')
  onChange () : void {
    // console.log(this.juniorMembers)
  }

  @Watch('seasonValue')
  onSeasonValueChange () : void {
    if (this.seasonValue.id !== 0) {
      console.log('[onSeasonValueChange()] Retrieving products information.')

      const activeSeasonId = Number(this.seasonValue.id)

      TeamsDataService.getAll('', null, `saeson_id.id=${activeSeasonId}&klubber_id.id=${this.memberData.clubID}`)
        .then((response) => {
          this.activeTeams = response.data

          this.retrieveProducts()
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  @Watch('changeClubModal')
  onModalChange (newVal: boolean) {
    if (!newVal) {
      this.changeClubValue = { id: 0, name: 'Vælg en klub' }
    }
  }

  @Watch('changePasswordModal')
  onPWModalChange (newVal: boolean) : void {
    if (!newVal) {
      this.newPassword = ''
      this.repeatNewPassword = ''
    }
  }

  public advMatchSetRoundsModeStateChanged (stateobj: { showModal: boolean, status: number }) {
    // console.log('[advMatchSetRoundStateChanged] stateobj = ' + JSON.stringify(stateobj))
  }

  public activateAdvancedMatchSetRoundsMode (readOnlyFlag: boolean, matchIndexNumber: number, matchSet: number) : void {
    // console.log('[activateAdvancedMatchSetRoundsEditMode] DONE.')

    this.advancedMatchSetRoundsModeData = { readOnlyFlag: readOnlyFlag, matchIndexNumber: matchIndexNumber, matchSet: [matchSet, matchSet] }
    this.advancedMatchSetModeModal = true
  }

  public toEventInfo (id : number, eventName : string, eventDate : string) : void {
    this.signedUpToModal = false
    const date = new Date(eventDate).toLocaleDateString('da-DK').replaceAll('.', '-')
    const eventSlug = id.toString() + '_' + CommonFunctions.slugify(eventName) + '-' + date

    // console.log(eventSlug)
    this.$router.push({ name: 'Event', params: { slug: eventSlug } })
  }

  public updatePassword () {
    if (!this.passwordTest(this.newPassword) || this.newPassword !== this.repeatNewPassword) {
      return this.$Message.danger({ text: 'Adgangskoden kunne ikke opdateres' })
    }

    const updatePW = {
      password: this.newPassword
    }

    UsersDataService.update(this.memberData.userId.toString(), updatePW)
      .then((response) => {
        // console.log('Password updated: ' + response.statusText)
        this.changePasswordModal = false
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public passwordTest (passwordToBeTested: string) : boolean {
    return CommonFunctions.strongPasswordTest(passwordToBeTested)
  }

  public approveRequest (requestID: number, memberID: number) {
    const request = this.newRequests.find(x => x.id === requestID)
    // console.log(request)
    const memberInfo = this.requesteeMembersArray.find(x => x.id === memberID)
    if (request !== undefined && memberInfo !== undefined) {
      let oldClub = {} as SysClub
      ClubsDataService.get(Number(request.klubskifte_gmlklub.id).toString())
        .then((response) => {
          oldClub = response.data
          // console.log('Old club data: ' + JSON.stringify(oldClub))
          if (oldClub.klubber_ansvarlig_id !== null && oldClub.klubber_ansvarlig_id.id === memberID) {
            const updateOldClub = {
              klubber_ansvarlig_id: null
            }
            ClubsDataService.update(Number(oldClub.id).toString(), updateOldClub)
              .then((response) => {
                // console.log('Removing the member from his post as "klubansvarlig')
                // console.log(response.data)
              })
              .catch((err) => {
                console.error(err)
              })
          }
          if (memberInfo.user_id.usrgroup !== defaultAdminBackendGroupId) {
            const updateUser = {
              usrgroup: defaultAdminBackendGroupId // Ensures that the member does not have any special rights in the new club.
            }
            UsersDataService.update(Number(memberInfo.user_id.id).toString(), updateUser)
              .then((response) => {
                // console.log('Change the member to have the rights of a ordinary member')
                // console.log(response.data)
              })
              .catch((err) => {
                console.error(err)
              })
          }
          const updateMember = {
            klubber_id: this.memberData.clubID,
            medlem_klubrolle: ClubRoleType.Medlem
          }
          const updateRequest = {
            klubskifte_god_afv: SysClubChangeFriendlyType.Godkend,
            klubskifte_status: false
          }

          const createMemberHistory = {
            medlemshistorik_handling: 'Klubskifte til: ' + this.memberData.clubName,
            medlem_id: memberID,
            klubber_id: this.memberData.clubID
          }

          MembersDataService.update(memberID.toString(), updateMember)
            .then((response) => {
              // console.log('Updating the member with his new club')
              // console.log(response.data)
            })
            .catch((err) => {
              console.error(err)
            })

          ClubChangeDataService.update(requestID.toString(), updateRequest)
            .then((response) => {
              // console.log('Updating the request')
              // console.log(response.data)
              this.newCheckNewRequests(this.memberData.clubID)
            })
            .catch((err) => {
              console.error(err)
            })

          History.HistoryDataService.create(createMemberHistory)
            .then((response) => {
              // console.log('Creating a entry in the member history')
              // console.log(response.data)
            })
            .catch((err) => {
              console.error(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  public denyRequest (requestID: number) {
    const updateRequest = {
      klubskifte_god_afv: SysClubChangeFriendlyType.Afvis,
      klubskifte_status: false
    }

    ClubChangeDataService.update(requestID.toString(), updateRequest)
      .then((response) => {
        // console.log(response.data)
        this.newCheckNewRequests(this.memberData.clubID)
      })
  }

  public underQuarantine (startDate: string | null = null, endDate: string | null = null) : boolean {
    if (startDate === '' || endDate === '') {
      return false
    }
    const start = (startDate === null ? '1970-01-01' : startDate)
    const end = (endDate === null ? '1970-01-01' : endDate)
    if (startDate === null || endDate === null) {
      return false
    }
    if (new Date(this.todaysDate).toISOString() > new Date(start).toISOString() && new Date(this.todaysDate).toISOString() < new Date(end).toISOString()) {
      return true
    }
    return false
  }

  expirationDate (dateString: string | null) : string {
    return (dateString === null ? '01/01/70' : this.toDanishDateString(dateString))
  }

  public validMembership (status: boolean, expirationDate: string | null) : boolean {
    const tempDate = (expirationDate === null ? '1970-01-01' : expirationDate)
    if (status && this.todaysDate > tempDate) {
      return true
    }
    return false
  }

  public findRequestNameFromID (memberID: number) : string {
    let retVal = 'Ikke fundet'
    const member = this.requesteeMembersArray.find(x => x.id === memberID)
    if (member !== undefined) {
      retVal = member.user_id.firstname + ' ' + member.user_id.lastname
    }
    return retVal
  }

  public displayRequesteeInfo (memberID: number) {
    // console.log(memberID)
    const tempValue = this.requesteeMembersArray.find(element => Number(element.id) === memberID)
    if (tempValue !== undefined) {
      this.requesteeInfo = {
        firstName: tempValue.user_id.firstname,
        lastName: tempValue.user_id.lastname,
        userName: tempValue.user_id.username,
        telephone: (tempValue.user_id.phone === null ? '' : Number(tempValue.user_id.phone).toString()),
        email: (tempValue.user_id.email === null ? '' : tempValue.user_id.email),
        address: tempValue.medlem_vejnummer,
        postalNumber: tempValue.bypost_id.bypost_postnummer,
        city: tempValue.bypost_id.bypost_by,
        paraDartPlayer: tempValue.medlem_paradart,
        gender: tempValue.koen_id.Koen,
        licenseExpires: tempValue.medlem_licens_slut,
        licenseNumber: (tempValue.medlem_licens === null ? '' : tempValue.medlem_licens.toString()),
        quarantineStartDate: tempValue.medlem_karantaene_dato,
        quarantineEndDate: tempValue.medlem_karantaene_slutdato,
        birthDate: tempValue.medlem_foedselsdag,
        clubName: tempValue.klubber_id.klubber_klubnavn,
        memberStatus: tempValue.medlem_status
      }
    }
    // console.log(this.requesteeInfo)
  }

  public cancelRequest (id: number) {
    ClubChangeDataService.delete(id.toString())
      .then((response) => {
        // console.log(response.data)
        this.retrieveClubChangeRequests()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public requestClubChange () {
    if (this.requestInProgress.length > 0) {
      return this.$Message.warning({ text: 'Du er allerede i gang med et klubskifte' })
    }

    const createRequest = {
      medlem_id: this.memberData.memberId,
      klubskifte_gmlklub: this.memberData.clubID,
      klubskifte_nyklub: this.changeClubValue.id,
      klubskifte_status: true,
      klubskifte_god_afv: SysClubChangeFriendlyType.Afventer
    }
    ClubChangeDataService.create(createRequest)
      .then((response) => {
        // console.log(response.data)
        this.retrieveClubChangeRequests()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public changeClub () {
    let tempClubs: SysClub[] = []
    if (!(this.changeClubOptions.length > 1)) {
      ClubsDataService.getAll('klubber_klubnavn:asc', null, 'klubber_status=true')
        .then((response) => {
          tempClubs = response.data

          for (const item of tempClubs) {
            this.changeClubOptions.push({ id: Number(item.id), name: item.klubber_klubnavn })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    this.changeClubModal = true
  }

  public editClubMembers () : void {
    this.$router.push({ name: 'ClubMembers', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public editMemberData () : void {
    this.$router.push({ name: 'EditYourInformation', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public editClubData () : void {
    this.$router.push({ name: 'EditClubInformation', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public createNewMember () : void {
    this.$router.push({ name: 'NewMember', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public orderHistory () : void {
    this.$router.push({ name: 'OrderHistory', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public courses () : void {
    this.$router.push({ name: 'Courses', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public clubEvents () : void {
    this.$router.push({ name: 'ClubEvents', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public clubCaptains () : void {
    this.$router.push({ name: 'ClubCaptains', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public shop () : void {
    this.$router.push({ name: 'Shop', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public clubTournaments () : void {
    this.$router.push({ name: 'ClubTournaments', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public clubTournamentMatch () : void {
    this.$router.push({ name: 'ClubTournamentMatchList', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public clubPointRules () : void {
    this.$router.push({ name: 'ClubPointRules', params: { userSlug: this.convertUserNameToSlug(this.memberData.memberId, this.memberData.userName) } })
  }

  public disableLicenseRenewalBtn () : boolean {
    const renewalOpen = new Date(new Date().getFullYear() + '-11-01').toISOString()
    if (this.memberData.licenseExpires === null || Date.parse(this.todaysDate) > Date.parse(this.memberData.licenseExpires) || Date.parse(this.todaysDate) >= Date.parse(renewalOpen)) {
      return true
    }
    return false
  }

  public disableClubLicenseRenewalBtn () : boolean {
    const renewalOpen = new Date(new Date().getFullYear() + '-11-01').toISOString()
    if (this.memberData.clubLicenseExpires === null || Date.parse(this.todaysDate) > Date.parse(this.memberData.clubLicenseExpires) || Date.parse(this.todaysDate) >= Date.parse(renewalOpen)) {
      return true
    }
    return false
  }

  public addClubLicenseRenewalToCart () {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }
    const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)

    for (const cartItem of cartItems) {
      if (cartItem.clubLicenseRenewal !== null && cartItem.clubLicenseRenewal !== undefined && cartItem.clubLicenseRenewal.clubID === this.memberData.clubID) {
        return this.$Message.warning({ text: 'Du har allerede tilføjet et klubkontigent til kurven' })
      }
    }
    let newItem = {} as SysCart

    newItem = {
      placeInCart: Number(cartItems.length),
      productName: this.licenseProduct.produkt_navn,
      productDescription: 'Klubkontingent til ' + this.memberData.clubName,
      price: this.licenseProduct.produkt_pris,
      dateInCart: this.todaysDate,
      expirationDate: null,
      playerRegistration: null,
      productId: defaultClubLicenseId,
      quantity: 1,
      licenseRenewal: null,
      clubLicenseRenewal: { clubID: this.memberData.clubID, newLicenseEndDate: this.clubLicenseEndDate() },
      eventSignUp: null,
      teamRegistration: null,
      competitionFeeData: null,
      orderExtraData: null
    }

    cartItems.push(newItem)

    localStorage.setItem('cart', JSON.stringify(cartItems))
    this.renewClubLicenseModal = false
    this.$router.push('/kassen')
  }

  public addLicenseRenewalToCart () {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }

    const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)

    for (const cartItem of cartItems) {
      if (cartItem.licenseRenewal !== null && cartItem.licenseRenewal.memberID === this.memberData.memberId) {
        return this.$Message.warning({ text: 'Du har allerede tilføjet en license fornyelse til kurven' })
      }
    }
    let newItem = {} as SysCart

    newItem = {
      placeInCart: Number(cartItems.length),
      productName: this.licenseProduct.produkt_navn,
      productDescription: 'Licens fornyelse til: ' + this.memberData.firstName + ' ' + this.memberData.lastName,
      price: this.calcLicensePrice(this.licenseProduct.produkt_pris),
      dateInCart: this.todaysDate,
      expirationDate: null,
      playerRegistration: null,
      productId: this.calculateAge(this.memberData.birthDate) <= 17 ? defaultJuniorLicenseId : defaultLicenseId,
      quantity: 1,
      licenseRenewal: { memberID: this.memberData.memberId, newLicenseEndDate: this.licenseEndDate() },
      clubLicenseRenewal: null,
      eventSignUp: null,
      teamRegistration: null,
      competitionFeeData: null,
      orderExtraData: null
    }
    cartItems.push(newItem)

    localStorage.setItem('cart', JSON.stringify(cartItems))
    this.renewLicenseModal = false
    this.$router.push('/kassen')
  }

  public calcLicensePrice (price: number) : number {
    // FIXME: Dato skal hentes korrekt fra produktets startdato.
    if ((this.memberData.licenseExpires === null || Date.parse(this.todaysDate) > Date.parse(this.memberData.licenseExpires)) && Date.parse(this.todaysDate) > Date.parse(this.seasonHalfWayPoint) && Date.parse(this.todaysDate) < Date.parse('2023-12-01T00:00:00')) {
      return price / 2
    }
    return price
  }

  public clubLicenseEndDate () : string {
    const currentYear: number = new Date().getFullYear()
    if (this.memberData.clubLicenseExpires === null || (Date.parse(this.memberData.clubLicenseExpires) + 30 * 24 * 60 * 60 * 1000) < Date.parse(this.todaysDate)) {
      return (currentYear).toString() + '-12-31'
    }
    return (currentYear + 1).toString() + '-12-31'
  }

  public licenseEndDate () : string {
    const currentYear: number = new Date().getFullYear()

    // FIXME: Dato skal hentes korrekt fra produktets startdato.
    if ((this.memberData.licenseExpires === null || Date.parse(this.memberData.licenseExpires) < Date.parse(this.todaysDate)) && (Date.parse(this.todaysDate) < Date.parse('2023-11-30T00:00:00') || Date.parse(this.todaysDate) >= Date.parse('2024-01-01T00:00:00'))) {
      return (currentYear).toString() + '-12-31'
    }
    return (currentYear + 1).toString() + '-12-31'
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public renewLicense () : void {
    this.renewLicenseModal = true
    const productId = (this.calculateAge(this.memberData.birthDate) <= 17 ? defaultJuniorLicenseId : defaultLicenseId)
    Products.ProductsDataService.get(productId.toString())
      .then((response) => {
        this.licenseProduct = response.data
        // console.log(this.licenseProduct)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public renewClubLicense () : void {
    this.renewClubLicenseModal = true
    Products.ProductsDataService.get(defaultClubLicenseId.toString())
      .then((response) => {
        this.licenseProduct = response.data
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public convertUserNameToSlug (id: number, userName: string) : string {
    return id.toString() + '-' + CommonFunctions.slugify(userName)
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const memberId = route.params.userSlug.toString().split('-')[0]
    if (Number(memberId) !== undefined) {
      retVal = Number(memberId)
    }
    return retVal
  }

  public retrieveMemberData () {
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      // console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        this.tempMember = response.data
        // if (this.tempMember.length !== 1) {
        //   this.$Message.danger({ text: 'Fejl: Noget gik galt' })
        //   return
        // }
        // // A rudementary check, if the memberId from the URL, does not match the memberId retrieved via the userId, logout and go to login page

        if (this.memberId !== Number(this.tempMember[0].id)) {
          AuthService.logout()
          CommonFunctions.redirectLogin()
        }

        // console.log(this.tempMember[0].user_id.usrgroup)

        this.memberData = {
          firstName: this.tempMember[0].user_id.firstname,
          lastName: this.tempMember[0].user_id.lastname,
          userGroup: this.tempMember[0].user_id.usrgroup,
          memberId: Number(this.tempMember[0].id),
          userName: this.tempMember[0].user_id.username,
          userId: Number(this.tempMember[0].user_id.id),
          licenseExpires: this.tempMember[0].medlem_licens_slut,
          birthDate: this.tempMember[0].medlem_foedselsdag,
          clubID: (this.tempMember[0].klubber_id === null ? 0 : Number(this.tempMember[0].klubber_id.id)),
          clubName: (this.tempMember[0].klubber_id === null ? '' : this.tempMember[0].klubber_id.klubber_klubnavn),
          clubLicenseExpires: null
        }
        // console.log(this.memberData.userGroup)
        if (this.memberData.userGroup === defaultClubManagerGroupId) {
          // Checks if any new request for club change have arrived
          this.memberData.clubLicenseExpires = this.tempMember[0].klubber_id.klubber_kontingent_slut
          this.newCheckNewRequests(this.memberData.clubID)
        }
        this.memberData.clubID && this.getClubRaekke(this.memberData.clubID)
        if (this.memberData.userGroup === defaultClubManagerGroupId || this.memberData.userGroup === defaultClubCaptainGroupId) {
          this.retrieveMatchChangeRequest(this.memberData.clubID)
          this.retrieveTournamentCategories()
        }
        // console.log(this.memberData)
        this.retrieveClubChangeRequests()
        this.retrieveCourseRegistrations()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public denyMatchDateChange (requestId: number) : void {
    const updateRequest = {
      kampflytning_status: false,
      kampflytning_anmodning: SysMatchRequestFriendlyType.Afvis
    }
    MatchDateChangeDataService.update(requestId.toString(), updateRequest)
      .then((response) => {
        // console.log('Match Date Change Denied: ' + response.statusText)
        this.retrieveMatchChangeRequest(this.memberData.clubID)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public approveMatchDateChange (requestId: number, matchId: number, newMatchDate: string) : void {
    let matchInfo = {} as SysTournamentMatchSchedule
    // console.log('[approveMatchDateChange()]' + matchId.toString())

    const retrieveMatchInfo = async (matchId: number) => {
      await Tournaments.TournamentMatchSchedulesDataService.getAll('', null, `id=${matchId.toString()}`)
        .then((response) => {
          const tempMatches: SysTournamentMatchSchedule[] = response.data
          const index = tempMatches.findIndex(el => Number(el.id) === Number(matchId))
          if (index !== -1) {
            matchInfo = tempMatches[index]
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const promises = []
    promises.push(retrieveMatchInfo(matchId))
    Promise.all(promises)
      .then(() => {
        const updateMatchSchedule = {
          kampprogram_nydato: (newMatchDate.length > 0 ? (new Date(newMatchDate)).toISOString() : null)
        }
        const updateRequest = {
          kampflytning_status: false,
          kampflytning_anmodning: SysMatchRequestFriendlyType.Godkend
        }
        Tournaments.TournamentMatchSchedulesDataService.update(Number(matchInfo.id).toString(), updateMatchSchedule)
          .then((response) => {
            // console.log('Updating match schedule: ' + response.statusText)

            MatchDateChangeDataService.update(requestId.toString(), updateRequest)
              .then((response) => {
                // console.log('Updating request: ' + response.statusText)
                this.retrieveMatchChangeRequest(this.memberData.clubID)
              })
              .catch((err) => {
                console.error(err)
              })
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 3) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public matchRequestDate (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 3) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public danishDateString (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString)
  }

  public hoursAndMinutes (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public retrieveMatchChangeRequest (clubId: number) {
    let requstParameter = ''
    let currentSeasonId = 0
    SeasonsDataService.getAll('saeson_start:asc', null, `saeson_slut_gte=${this.todaysDate}`)
      .then((response) => {
        currentSeasonId = response.data[0].id
        // console.log('Current Season Id: ' + currentSeasonId)
        TeamsDataService.getAll('', null, `klubber_id.id=${clubId}&saeson_id.id=${currentSeasonId}`)
          .then((response) => {
            this.teams = response.data
            // console.log(this.teams)
            if (this.teams.length > 0) {
              for (let i = 0; i < this.teams.length; i++) {
                requstParameter += `&_where[_or][${i}][modtager_hold_id.id]=${this.teams[i].id}`
              }
              MatchDateChangeDataService.getAll('', null, 'kampflytning_status=true' + requstParameter)
                .then((response) => {
                  this.matchChangeRequest = response.data

                  if (this.matchChangeRequest.length === 0 && this.newRequests.length === 0) {
                    this.newRequestsModal = false
                  }
                })
                .catch((err) => {
                  console.error(err)
                })
            }
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public newCheckNewRequests (clubID: number) {
    ClubChangeDataService.getAll('', null, `klubskifte_nyklub.id=${clubID.toString()}&klubskifte_status=true`)
      .then((response) => {
        this.newRequests = response.data
        if (this.newRequests.length > 0) {
          this.retrieveNamesOfRequests()
        }
        if (this.matchChangeRequest.length === 0 && this.newRequests.length === 0) {
          this.newRequestsModal = false
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveNamesOfRequests () : void {
    let memberIDs = ''
    for (const item of this.newRequests) {
      memberIDs += '&id=' + item.medlem_id.id
    }
    memberIDs = memberIDs.slice(1)
    MembersDataService.getAll('', null, memberIDs)
      .then((response) => {
        this.requesteeMembersArray = response.data
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveClubChangeRequests () : void {
    ClubChangeDataService.getAll('', null, `medlem_id.id=${this.memberData.memberId.toString()}&klubskifte_status=true`)
      .then((response) => {
        this.requestInProgress = response.data
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // this.todaysDate = new Date(response.data.replace(/\n/g, '')).toISOString().split('T')[0]
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // console.log(this.todaysDate)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveCourseRegistrations () : void {
    Events.EventRegistrationDataService.getAll('', null, `medlem_id.id=${this.memberData.memberId}&event_id.event_start_gte=${new Date(this.todaysDate).toISOString()}`)
      .then((response) => {
        this.signedUpTo = response.data
        // console.log(this.signedUpTo)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public calculateAge (birthDate: string) : number {
    const ageDifMs = Date.parse(this.todaysDate) - new Date(birthDate).getTime()
    const ageDate = new Date(ageDifMs)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  public retrieveTournamentCategories () : void {
    Tournaments.TournamentCategoriesDataService.getAll('', null, 'turneringskategori_status=true')
      .then((response) => {
        this.tournamentCategories = response.data
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public getCategoryFromTeamId (teamId: number) : string {
    let retVal = ''
    const team = this.teams.find(el => Number(el.id) === Number(teamId))

    if (team === undefined) {
      return retVal
    }

    const category = this.tournamentCategories.find(el => el.id === team.raekke_id.turneringskategori_id)
    if (category === undefined) {
      return retVal
    }

    retVal = category.turneringskategori_navn

    return retVal
  }

  public retrieveCategoriesForTeams () : void {
    this.categoriesForTeamSignUp = []
    let tempCategories: SysTournamentCategoryId[] = []

    Tournaments.TournamentCategoriesDataService.getAll('', null, 'turneringskategori_status=true')
      .then((response) => {
        tempCategories = response.data
        for (const item of tempCategories) {
          this.categoriesForTeamSignUp.push({
            categoryId: Number(item.id),
            categoryName: item.turneringskategori_navn,
            categoryPrice: item.turneringskategori_pris,
            numberOfTeams: 0
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public isDisabled () : boolean {
    if (Object.keys(this.nextSeason).length > 0) {
      return false
    }
    return true
  }

  public teamManagement () : void {
    // this.retrieveSeasons()
    // this.retrieveCategoriesForTeams()
    this.retrieveTeamInfo()
    this.retrieveJuniorPlayers()
    this.manageTeamModal = true
  }

  public async retrieveJuniorPlayers () : Promise<void> {
    this.juniorMemberOptions = []
    const year = new Date().getFullYear()

    const maxAge = new Date((year - 18) + '-01-01').toISOString().split('T')[0]
    // console.log(maxAge)

    MembersDataService.getAll('', null, `medlem_foedselsdag_gte=${maxAge}`)
      .then((response) => {
        const tempMembers = response.data as SysMember[]
        // console.log(tempMembers)
        for (const item of tempMembers) {
          this.juniorMemberOptions.push({ id: Number(item.id), name: item.user_id.firstname + ' ' + item.user_id.lastname })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public async retrieveTeamInfo () : Promise<void> {
    const seasonPromise = SeasonsDataService.getAll('', null, 'saeson_status=true')
    const categoryPromise = Tournaments.TournamentCategoriesDataService.getAll('', null, 'turneringskategori_status=true')

    await Promise.all([seasonPromise, categoryPromise])
      .then((response) => {
        const allSeasons = response[0].data as SysSaeson[]
        const teamCategories = response[1].data as SysTournamentCategoryId[]
        this.categoriesForTeamSignUp = []

        for (const item of teamCategories) {
          this.categoriesForTeamSignUp.push({
            categoryId: Number(item.id),
            categoryName: item.turneringskategori_navn,
            categoryPrice: item.turneringskategori_pris,
            numberOfTeams: 0
          })
        }

        const tempPrevSeason = allSeasons.filter(el => Date.parse(el.saeson_slut) < Date.parse(this.todaysDate))
        // Finding the Prev season, if more than one season meets the criteria, then pick the one with the highest id.
        if (tempPrevSeason.length > 1) {
          this.prevSeason = tempPrevSeason.reduce((prev, current) => (Number(prev.id) > Number(current.id)) ? prev : current)
        } else if (tempPrevSeason[0] !== undefined) {
          this.prevSeason = tempPrevSeason[0]
        }

        // Finding the current Season.
        const tempCurrentSeason = allSeasons.find(el => Date.parse(this.todaysDate) < Date.parse(el.saeson_slut) && Date.parse(this.todaysDate) > Date.parse(el.saeson_start))
        if (tempCurrentSeason !== undefined) {
          this.currentSeason = tempCurrentSeason
        }

        // Finding the comming season, if it exists.
        const tempNextSeason = allSeasons.find(el => Date.parse(this.todaysDate) <= Date.parse(el.saeson_tilmelding))
        if (tempNextSeason !== undefined) {
          this.nextSeason = tempNextSeason
        }

        // console.log('Next Season' + JSON.stringify(this.nextSeason))
        // console.log('Current Season' + JSON.stringify(this.currentSeason))
        // console.log('Prev Season' + JSON.stringify(this.prevSeason))
        // console.log(allSeasons)

        this.seasonOptions = []

        for (const item of allSeasons) {
          this.seasonOptions.push({ id: Number(item.id), name: item.saeson_navn })
        }

        this.seasonOptions.unshift({ id: 0, name: 'Vælg Sæson' })
        const highestId = Math.max(...this.seasonOptions.map(o => Number(o.id)))
        const index = this.seasonOptions.findIndex(el => el.id === highestId)
        this.seasonValue = this.seasonOptions[index]
        let activeSeasonId: number | null = (Object.keys(this.currentSeason).length === 0 ? this.prevSeason.id : this.currentSeason.id)
        activeSeasonId = (this.seasonValue.id === null || Number(this.seasonValue.id) === 0 ? activeSeasonId : Number(this.seasonValue.id))

        this.retrieveProducts()

        TeamsDataService.getAll('', null, `saeson_id.id=${activeSeasonId}&klubber_id.id=${this.memberData.clubID}`)
          .then((response) => {
            this.activeTeams = response.data
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public async retrieveProducts () : Promise<void> {
    Products.ProductsDataService.getAll('', null, `produkt_type_id.produkt_type_beskrivelse_contains=holdtilmelding&produkt_status=true&produkt_navn_contains=${this.seasonValue.name}`)
      .then((response) => {
        this.teamProducts = response.data
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public findLastSignupDate (categoryName: string) : string {
    let retVal = ''

    if (this.teamProducts !== undefined) {
      const index = this.teamProducts.findIndex(el => el.produkt_navn.toLowerCase().includes(categoryName.toLowerCase()))

      if (index === -1) {
        return retVal
      }

      retVal = CommonFunctions.toDanishDateString(this.teamProducts[index].produkt_datotil)
    }

    return retVal
  }

  public retrieveSeasons () : void {
    let allSeasons: SysSaeson[] = []
    SeasonsDataService.getAll('', null, 'saeson_status=true')
      .then((response) => {
        allSeasons = response.data
        const tempPrevSeason = allSeasons.filter(el => Date.parse(el.saeson_slut) < Date.parse(this.todaysDate))

        // Finding the Prev season, if more than one season meets the criteria, then pick the one with the highest id.
        if (tempPrevSeason.length > 1) {
          this.prevSeason = tempPrevSeason.reduce((prev, current) => (Number(prev.id) > Number(current.id)) ? prev : current)
        } else if (tempPrevSeason[0] !== undefined) {
          this.prevSeason = tempPrevSeason[0]
        }

        // Finding the current Season.
        const tempCurrentSeason = allSeasons.find(el => Date.parse(this.todaysDate) < Date.parse(el.saeson_slut) && Date.parse(this.todaysDate) > Date.parse(el.saeson_start))
        if (tempCurrentSeason !== undefined) {
          this.currentSeason = tempCurrentSeason
        }

        // Finding the comming season, if it exists.
        const tempNextSeason = allSeasons.find(el => Date.parse(this.todaysDate) <= Date.parse(el.saeson_tilmelding))
        if (tempNextSeason !== undefined) {
          this.nextSeason = tempNextSeason
        }

        // console.log('Next Season' + JSON.stringify(this.nextSeason))
        // console.log('Current Season' + JSON.stringify(this.currentSeason))
        // console.log('Prev Season' + JSON.stringify(this.prevSeason))
        // console.log(allSeasons)

        const activeSeasonId = (Object.keys(this.currentSeason).length === 0 ? this.prevSeason.id : this.currentSeason.id)
        for (const item of allSeasons) {
          this.seasonOptions.push({ id: Number(item.id), name: item.saeson_navn })
        }

        this.seasonOptions.unshift({ id: 0, name: 'Vælg Sæson' })
        const highestId = Math.max(...this.seasonOptions.map(o => Number(o.id)))
        const index = this.seasonOptions.findIndex(el => el.id === highestId)
        this.seasonValue = this.seasonOptions[index]

        TeamsDataService.getAll('', null, `saeson_id.id=${activeSeasonId}&klubber_id.id=${this.memberData.clubID}`)
          .then((response) => {
            this.activeTeams = response.data
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public currentRegisteredTeams (categoryId: number) : number {
    let retVal = 0

    if (this.activeTeams.length > 0) {
      retVal = this.activeTeams.filter(el => el.raekke_id.turneringskategori_id === categoryId).length
    }

    return retVal
  }

  public async newRegisterTeams () : Promise<void> {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }
    const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)

    for (const item of this.categoriesForTeamSignUp) {
      const indexOfTeamProduct = this.teamProducts.findIndex(el => el.produkt_navn.toLowerCase().includes(item.categoryName.toLowerCase()))
      // console.log('[newRegisterTeams()] teamProducts = ' + JSON.stringify(this.teamProducts))

      if (indexOfTeamProduct >= 0) {
        const lastDateString = this.teamProducts[indexOfTeamProduct].produkt_datotil

        if (item.numberOfTeams > 0 && (Number.isNaN(Date.parse(lastDateString)) || Date.parse(lastDateString) < Date.parse(this.todaysDate))) {
          this.$Message.danger({ text: 'Kan ikke tilmelde et eller flere hold på grund af et problem med dato.' })

          return
        }

        const juniorObj = Object.assign([], this.juniorMembers)
        // console.log(juniorObj.length)
        // console.log(juniorObj[0])
        // BUG
        // if (item.numberOfTeams > 0 && this.categoriesForTeamSignUp.some(el => el.categoryName.toLowerCase().includes('junior') && el.numberOfTeams !== juniorObj.length)) {
        //   this.$Message.danger({ text: 'Antallet af juniorspillere passer ikke med antallet af tilmedlinger' })
        //   return
        // }

        if (item.numberOfTeams > 0) {
          // console.log(this.teamProducts)
          const productIndex = this.teamProducts.findIndex(el => el.produkt_navn.toLowerCase().includes(item.categoryName.toLowerCase()))
          // console.log(productIndex)

          if (productIndex !== -1) {
            const tempProduct = this.teamProducts[productIndex]

            if (Date.parse(tempProduct.produkt_datofra) > Date.parse(this.todaysDate)) {
              this.$Message.danger({ text: item.categoryName + ' kan ikke købes endnu, prøv igen efter den ' + CommonFunctions.toDanishDateString(tempProduct.produkt_datofra) })

              return
            }

            let rowResponse: SysTournamentRow[] = []

            try {
              const response = await Tournaments.TournamentRowsDataService.getAll('', null, `saeson_id=${this.seasonValue.id}&turneringskategori_id.turneringskategori_navn=${item.categoryName}`)
              rowResponse = response.data
            } catch (err) {
              console.log(err)
            }

            for (let i = 0; i < item.numberOfTeams; i++) {
              let newItem = {} as SysCart
              const teamRegistration = {
                teamStatus: true,
                teamName: (item.categoryName.toLowerCase().includes('junior') ? this.juniorMembers[i].name : this.memberData.clubName + ' ' + (i + 1)),
                clubId: this.memberData.clubID,
                rowId: (rowResponse.length === 0 ? null : Number(rowResponse[0].id)),
                seasonId: this.seasonValue.id
              } as SysTeamRegistration

              newItem = {
                placeInCart: Number(cartItems.length),
                productName: tempProduct.produkt_navn,
                productDescription: (item.categoryName.toLowerCase().includes('junior') ? 'Tilmelding af ' + this.juniorMembers[i].name + ' til ' + item.categoryName : tempProduct.produkt_beskrivelse),
                price: tempProduct.produkt_pris,
                dateInCart: this.todaysDate,
                expirationDate: tempProduct.produkt_datotil,
                productId: Number(tempProduct.id),
                quantity: 1,
                playerRegistration: null,
                licenseRenewal: null,
                clubLicenseRenewal: null,
                eventSignUp: null,
                teamRegistration: teamRegistration,
                competitionFeeData: null,
                orderExtraData: null
              }

              cartItems.push(newItem)
            }
          }
        }
      }
    }

    // console.log(cartItems)
    if (cartItems.length >= 1) {
      localStorage.setItem('cart', JSON.stringify(cartItems))
      this.$router.push('/kassen')
    } else {
      this.$Message.danger({ text: 'Der blev ikke fundet noget produkt, hvis navn indeholdte kategorinavnet af den nødvendige kategori for denne holdtilmelding.' })
    }

    this.manageTeamModal = false
  }

  public registerTeams () : void {
    // First search the products, to see if they have already been created.
    // console.log(this.currentSeason.saeson_tilmelding)
    const teamProducts: SysProduct[] = []
    const getProductInfo = async (category: { id: number, name: string, price: number }, productString: string) => {
      await Products.ProductsDataService.getAll('', null, productString)
        .then((response) => {
          const temp: SysProduct[] = response.data

          if (temp.length > 0) {
            teamProducts.push(temp[0])
          } else {
            // console.log('Creating new Product: ' + category.name)
            const createProduct = {
              produkt_status: true,
              produkt_navn: 'Tilmelding til ' + category.name + ' for ' + this.nextSeason.saeson_navn.toLowerCase(), // Should be this.nextSeason instead of this.currentSeason
              produkt_datofra: this.todaysDate,
              produkt_pris: category.price,
              produkt_datotil: this.currentSeason.saeson_tilmelding, // Should be this.nextSeason instead of this.currentSeason .
              produkt_beskrivelse: 'Holdtilmelding til ' + category.name,
              user_id: this.memberData.userId, // Not sure if this is correct.
              produkt_type_id: 13
            }

            Products.ProductsDataService.create(createProduct)
              .then((response) => {
                const newProduct: SysProduct = response.data
                teamProducts.push(newProduct)
              })
              .catch((err) => {
                console.error(err)
              })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    // console.log(this.currentSeason.saeson_navn)
    const promises = []

    for (const item of this.categoriesForTeamSignUp) {
      const oldBoys = item.categoryName.slice(0, -1)
      const searchTerm = `produkt_navn_contains=${(item.categoryName.includes('+') ? oldBoys : item.categoryName)}&produkt_datotil_lte=${this.todaysDate}` // Should be this produkt_datotil_gte=${this.todaysDate}. But currently if you do this, the a new Produdct will ALWAYS be created

      promises.push(getProductInfo({ id: item.categoryId, name: item.categoryName, price: item.categoryPrice }, searchTerm))
    }

    Promise.all(promises)
      .then(() => {
        if (!localStorage.getItem('cart')) {
          localStorage.setItem('cart', JSON.stringify([]))
        }

        // console.log(teamProducts)
        const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)
        let newItem = {} as SysCart

        for (const item of this.categoriesForTeamSignUp) {
          if (item.numberOfTeams > 0) {
            const tempObj = teamProducts.find(el => el.produkt_navn.includes(item.categoryName))
            let product = {} as SysProduct

            if (tempObj !== undefined) {
              product = tempObj
            }

            for (let i = 0; i < item.numberOfTeams; i++) {
              newItem = {
                placeInCart: Number(cartItems.length),
                productName: product.produkt_navn,
                productDescription: this.memberData.clubName + ' ' + (i + 1) + ': ' + product.produkt_navn.toLowerCase(),
                price: product.produkt_pris,
                dateInCart: this.todaysDate,
                expirationDate: '2023-10-03', // Fixme: should be variable date, that comes from the tournament last registration date.
                productId: Number(product.id),
                quantity: 1,
                playerRegistration: null,
                licenseRenewal: null,
                clubLicenseRenewal: null,
                eventSignUp: null,
                teamRegistration: null,
                competitionFeeData: null,
                orderExtraData: null
              }

              cartItems.push(newItem)
            }
          }
        }
        localStorage.setItem('cart', JSON.stringify(cartItems))
        this.manageTeamModal = false
        this.$router.push('/kassen')
      })
      .catch((err) => {
        console.error(err)
      })
    // for (const item of this.categoriesForTeamSignUp) {
    //   if (item.numberOfTeams > 0) {
    //     console.log('Number of team to sign up: ' + item.numberOfTeams + ' [' + this.currentRegisteredTeams(item.categoryId) + ']')
    //     if (this.currentRegisteredTeams(item.categoryId) > 0) {
    //       for (const team of this.activeTeams) {
    //         if (team.raekke_id.turneringskategori_id === item.categoryId) {
    //           console.log(team.hold_holdnavn + ', ' + team.raekke_id.raekke_navn)
    //         }
    //       }
    //     }
    //     if (item.numberOfTeams > this.currentRegisteredTeams(item.categoryId)) {
    //       const additionalTeams = item.numberOfTeams - this.currentRegisteredTeams(item.categoryId)
    //       console.log('Create: ' + additionalTeams + ' additional teams for ' + item.categoryName)
    //     }
    //   }
    // }
  }

  public async getClubRaekke (clubId: number) {
    try {
      const clubRaekkeResponse: any = await ClubsDataService.getClubberRaekke(clubId.toString())
      this.clubRaekke = clubRaekkeResponse.data
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  get isAbleToCreateClubTournament () : boolean {
    if (this.clubRaekke?.length > 0) {
      return true
    } else {
      return false
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveMemberData()
      this.retrieveDateTimeFromServer()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
